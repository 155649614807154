import React from 'react'
import AppRouter from './AppRouter'
import {BrowserRouter} from 'react-router-dom'
import {AuthContextProvider} from '../contexts/AuthContext'

function App() {
    return (
        <BrowserRouter>
            <AuthContextProvider>
                <AppRouter />
            </AuthContextProvider>
        </BrowserRouter>
    )
}

export default App
