import React, {useState} from 'react'

const AuthContext = React.createContext()

export const AuthContextProvider = (props) => {
    const [isLoggedIn, setLoggedIn] = useState(false)

    const {children} = props
    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                setLoggedIn
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
