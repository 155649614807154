import {ApolloClient, createHttpLink} from '@apollo/client'
import {setContext} from 'apollo-link-context'
import {getIdToken} from '../components/auth/auth'
import apiEndpoint from '../components/api/sync-api-endpoint/api-endpoint'
import typeDefs from './typeDefs'
import cache from './cache'

const link = createHttpLink({
    uri: apiEndpoint.apiEndpoint
})

const authLink = setContext(async (_, {headers}) => {
    const tokenPromise = () =>
        new Promise((resolve) => {
            getIdToken(resolve, 'subscriber')
        })

    const token = await tokenPromise()
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    }
})

const client = new ApolloClient({
    cache,
    typeDefs,
    link: authLink.concat(link)
    // dataIdFromObject: (o) => o.id,
})

export default client
