import React from 'react'
import {Login as L} from '@savesolar/react-components'
import {loginBackground} from '../../../assets/img/index'
import {Form, notification} from 'antd'
import {resetPassword, sendCode, signIn} from '../../auth/auth'
import {currentUserVar} from '../../../graphql/cache'
import {useNavigate} from 'react-router-dom'

const LoginAdminComponent = () => {
    const [loginForm] = Form.useForm()
    const navigate = useNavigate()

    const onSigninSubmit = (values) => {
        signIn(values.username.toLowerCase(), values.password, onSignInResponse)
    }

    const onSignInResponse = (response, currentUser, error) => {
        if (response === 'success') {
            currentUserVar(currentUser)
            navigate('home')
        } else if (response === 'failure') {
            loginForm.resetFields()
            notification.error({
                message: 'Login unsuccessful',
                description: error
            })
        }
    }

    return (
        <L.Container background={loginBackground}>
            <L.Form form={loginForm} onFinish={onSigninSubmit}>
                <L.Header>
                    <L.Title title={'Admin Login'} subtitle={"Hello! Let's get started..."} />
                </L.Header>
                <L.Body>
                    <L.Input type="text" name="username" size="large" placeholder="Username" />
                    <L.Input
                        autoComplete="on"
                        type="password"
                        name="password"
                        size="large"
                        placeholder="Password"
                    />
                    <L.Button name="LOGIN" />
                    <L.ResetPasswordModal resetPassword={resetPassword} sendCode={sendCode} />
                </L.Body>
                <L.Footer>
                    <L.Credits />
                </L.Footer>
            </L.Form>
        </L.Container>
    )
}

export default LoginAdminComponent
