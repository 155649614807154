import {InMemoryCache, makeVar} from '@apollo/client'

export const currentUserVar = makeVar({})

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                currentUser: {
                    read() {
                        return currentUserVar()
                    }
                }
            }
        }
    }
})

export default cache
