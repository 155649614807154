import {useCallback, useContext, useEffect} from 'react'
import {useQuery} from '@apollo/client'
import {useRoutes} from 'react-router-dom'
import AuthContext from '../contexts/AuthContext'
import {currentUserVar} from '../graphql/cache'
import {CURRENT_USER} from '../graphql/queries/user'
import routes from '../routes'
import {getSession} from './auth/auth'

const authorize = (updateCurrentUser, currentUser) => {
    return new Promise((resolve) => {
        getSession(updateCurrentUser, currentUser, resolve)
    })
}

const AppRouter = () => {
    const {setLoggedIn, isLoggedIn} = useContext(AuthContext)

    const {
        data: {currentUser}
    } = useQuery(CURRENT_USER)

    const updateCurrentUser = (value) => {
        currentUserVar(value)
    }

    const checkAuthorized = useCallback(async () => {
        const authorized = await authorize(updateCurrentUser, currentUser)
        if (!authorized) {
            setLoggedIn(false)
        } else {
            setLoggedIn(true)
        }
    }, [currentUser, setLoggedIn])

    useEffect(() => {
        checkAuthorized()
    }, [checkAuthorized, isLoggedIn])

    const routing = useRoutes(routes(isLoggedIn))
    return routing
}

export default AppRouter
