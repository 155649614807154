import {gql} from '@apollo/client'

const typeDefs = gql`
    type AccessToken {
        jwtToken: String
    }
    type User {
        accessToken: AccessToken
    }
    extend type Query {
        currentUser: User
    }
`

export default typeDefs
