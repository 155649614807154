import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'

import {ApolloProvider} from '@apollo/client'
import client from './graphql/setup'

import './index.css'

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
