import {Button} from 'antd'
import React, {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import AuthContext from '../../../contexts/AuthContext'
import {signOut} from '../../auth/auth'

const LayoutComponent = () => {
    const authContext = useContext(AuthContext)
    const navigate = useNavigate()

    const handleSignOut = async () => {
        signOut()
        authContext.setLoggedIn(false)
        navigate('login')
    }

    return (
        <div>
            LayoutComponent
            <div>
                <Button onClick={() => handleSignOut()}>Sign out</Button>
            </div>
        </div>
    )
}

export default LayoutComponent
