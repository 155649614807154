import React from 'react'
import {Navigate} from 'react-router-dom'

import LoginAdminComponent from './components/app/LoginAdminComponent'
import LayoutComponent from './components/app/LayoutComponent'

const routes = (isLoggedIn) => [
    {
        element: isLoggedIn ? <LayoutComponent /> : <Navigate to="/login" />,
        children: [
            {path: 'home', element: <LayoutComponent />},
            {path: '/', element: <Navigate to="/home" />}
        ]
    },
    {
        element: !isLoggedIn ? <LoginAdminComponent /> : <Navigate to="/home" />,
        children: [
            {path: 'login', element: <LoginAdminComponent />},
            {path: '/', element: <Navigate to="/login" />}
        ]
    },
    {path: '*', element: <Navigate to="/" replace />}
]

export default routes
